<template>
  <div
    class="modal fade"
    id="marketing-email-template-preview-modal"
    ref="marketingEmailTemplatePreviewModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-xl modal-dialog-centered modal-fullscreen-md-down
      "
    >
      <div class="modal-content">
        <div class="modal-body overflow-scroll">
          <loading v-if="loading" />
          <div v-else>
            <div v-html="marketingEmailTemplate.html"></div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-light"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="far fa-times me-1" />
            Close Preview
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  props: ["marketingEmailTemplateId"],
  data() {
    return {
      modal: null,
      loading: true,
      marketingEmailTemplate: null,
    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.modal.hide();
    },
    fetchMarketingEmailTemplate() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/marketing-email-templates/" +
            this.marketingEmailTemplateId
        )
        .then(({ data }) => {
          this.marketingEmailTemplate = data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.marketingEmailTemplatePreviewModal);
    this.modal.show();

    const vm = this;
    this.$refs.marketingEmailTemplatePreviewModal.addEventListener(
      "hide.bs.modal",
      function () {
        vm.$emit("closed");
      }
    );
  },
  created() {
    this.fetchMarketingEmailTemplate();
  },
};
</script>
