<template>
  <div>
    <div class="card bg-light border-0 mb-3 shadow-none">
      <div class="card-body p-0 p-md-3">

        <div class="d-flex">
          <button class="btn btn-light d-block mb-3" @click="closeEmail">
            <i class="far fa-arrow-left me-1" />{{ $t('views.inbox.emailview.back') }}</button>
        </div>

        <div class="mb-3">
          <div class="card bg-white border-0 shadow-none mb-2">
            <div class="card-body">
              <div class="card-label bg-primary text-white">{{email.sent_date | formatDate}}</div>

              <div class="form-group row mt-4 mb-2">
                <label class="col-lg-1 col-form-label"></label>
                <div class="col-lg-11">
                  <h5 class="fw-bold mt-2 mb-0">{{email.subject}}</h5>
                </div>
              </div>

              <div class="form-group row mb-2">
                <label class="col-lg-1 col-form-label">From</label>
                <div class="col-lg-11">
                  <input type="text" class="form-control d-inline" disabled :value="email.from" />
                </div>
              </div>
              <div class="form-group row mb-3">
                <label class="col-lg-1 col-form-label">To</label>
                <div class="col-lg-11">
                  <input type="text" class="form-control d-inline" disabled :value="email.to" />
                </div>
              </div>

              <div class="row">
                <div class="col-auto" v-if="email.delivered">
                  <p class="badge rounded-pill bg-success mb-0">
                    <i class="far fa-check-circle me-1" />
                    Delivered
                  </p>
                </div>
                <div class="col-auto" v-if="email.opened">
                  <p class="badge rounded-pill bg-success mb-0">
                    Opened: {{ email.opened }}
                  </p>
                </div>
                <div class="col-auto" v-if="email.clicked">
                  <p class="badge rounded-pill bg-success mb-0">
                    Clicked: {{ email.clicked }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card bg-white shadow-none">
            <div class="card-body">
              <div class="embed-responsive embed-responsive-16by9 w-100">

                <iframe class="embed-responsive-item" style="overflow: scroll" :srcdoc="email.mailBody" width="100%" height="500px" >

                </iframe>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: ["email"],
  data() {
    return {
      value: this.emails,
    };
  },
  methods: {
    closeEmail() {
      this.$emit("closed");
    },
    explodeArray(arrayToExplode) {
      if (arrayToExplode) {
        if (typeof arrayToExplode === "string") { arrayToExplode = JSON.parse(arrayToExplode)}
        let str = "";
        arrayToExplode.forEach((item, index) => {
          if (index > 0) {
            str = str + ", "
          }
          str = str + item;
        })
        return str;
      }
      return "";
    },
  },
};
</script>

<style>
</style>
