<template>
  <div>
    <loading v-if="loading" />
    <div v-else>
      <marketing-email-view
        v-if="viewingEmail"
        :email="viewingEmail"
        @closed="viewingEmail = null"
      />

      <div v-else>
        <div class="row">
          <div class="col">
            <div class="card bg-light border-0 shadow-none h-100 mb-3">
              <div class="card-body">
                <div class="row mb-3 justify-content-between">
                  <div class="col my-auto d-flex">
                    <input
                      type="text"
                      v-model="searchStr"
                      class="form-control form-control d-inline"
                      :placeholder="$t('views.inbox.emailinbox.search')"
                      @input="performSearch"
                    />

                    <button
                      class="btn btn btn-light d-inline ms-2"
                      @click="clearSearch"
                    >
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>
                <div v-if="emails.length === 0" class="text-center mb-3">
                  <i class="fal fa-search fa-3x moving-gradient-text mb-3" />
                  <h5 class="fw-normal">
                    {{ $t("views.inbox.emailinbox.noEmailsFound") }}
                  </h5>
                </div>

                <div
                  class="row"
                  v-for="email in emails"
                  :key="email.id"
                  @click="viewingEmail = email"
                >
                  <div class="col">
                    <div
                      class="
                        bg-white
                        rounded
                        shadow-sm
                        mb-3
                        px-4
                        py-3
                        cursor-pointer
                        crank-table-row-link
                      "
                    >
                      <div class="row">
                        <div
                          class="
                            col col-3
                            wrap-ellipses
                            my-auto
                            text-primary
                            fw-bold
                          "
                          v-if="sendout && email.contact"
                        >
                          <img
                            :src="email.contact.profile_pic_url"
                            alt="contact"
                            width="32"
                            height="32"
                            class="me-2 rounded-circle"
                          />
                          <span> {{ email.contact.name }} </span>
                        </div>
                        <div class="col my-auto wrap-ellipses fw-bold">
                          <span class="align-middle">{{ email.subject }}</span>
                          <i v-if="!email.subject">{{
                            $t("views.inbox.emailinbox.noSubject")
                          }}</i>
                        </div>
                        <div
                          class="col-auto my-auto pe-0"
                          v-if="email.delivered"
                        >
                          <p class="badge rounded-pill bg-success my-auto">
                            <i class="far fa-check-circle me-lg-1" />
                            <span class="d-none d-lg-inline-block"
                              >Delivered</span
                            >
                          </p>
                        </div>
                        <div class="col-auto my-auto pe-0" v-if="email.opened">
                          <p class="badge rounded-pill bg-secondary my-auto">
                            <i class="far fa-check-circle me-lg-1" />
                            <span class="d-none d-lg-inline-block">Read</span>
                          </p>
                        </div>
                        <div
                          class="col-auto text-end my-auto d-none d-md-block"
                        >
                          {{ email.sent_date | formatDate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <pagination
                  :pagination="pagination"
                  @navigate="fetchMarketingEmails"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import Pagination from "@/views/components/tables/Pagination";
import EmailView from "@/views/components/inbox/EmailView";
import MarketingEmailView from "@/views/components/inbox/MarketingEmailView";

export default {
  props: ["sendout", "contact"],
  data() {
    return {
      loading: true,
      emails: [],
      pagination: {},
      viewingEmail: null,
      searchStr: "",
    };
  },
  computed: {},
  methods: {
    fetchMarketingEmails(paginationUrl) {
      let baseUrl;
      if (this.contact) {
        baseUrl = "/contacts/" + this.contact.id + "/marketing-emails";
      } else if (this.sendout) {
        baseUrl =
          "/marketing-sendouts/" + this.sendout.id + "/marketing-emails";
      } else {
        baseUrl = "/marketing-emails";
      }

      let url = paginationUrl ?? process.env.VUE_APP_API_URL + baseUrl;

      if (this.searchStr) {
        url = this.updateQueryStringParameter(url, "search", this.searchStr);
      }

      this.$axios.get(url).then(({ data }) => {
        this.pagination = data;
        this.emails = data.data;
        this.loading = false;
      });
    },
    clearSearch() {
      this.searchStr = "";
      this.fetchMarketingEmails();
    },
    performSearch: debounce(function () {
      this.fetchMarketingEmails();
    }, 300),
  },
  watch: {},
  mounted() {
    this.fetchMarketingEmails();
  },
  filters: {
    formatDate(date) {
      return moment.duration(moment(date).diff(moment.now())).humanize(true);
    },
  },
  components: {
    MarketingEmailView,
    EmailView,
    Pagination,
  },
};
</script>

<style>
</style>
