<template>
  <div>
    <div class="container" v-if="sendout">
      <div class="card border-0 shadow-none bg-light mb-4">
        <div class="card-body">
          <h1 class="text-secondary fw-bold mb-0">{{ sendout.name }}</h1>
          <h5>{{ sendout.subject }}</h5>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-4">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body" v-if="!sendout.sent">
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-0">Send At</h4>
                  <p
                    v-if="!editSendingDate"
                    class="fw-bold text-secondary text-start mb-0 py-2"
                  >
                    {{ sendout.send_at | formatDate }}
                  </p>
                  <input
                    v-else
                    type="datetime-local"
                    class="form-control form-control-sm py-2"
                    v-model="sendout.send_at"
                    @change="updateSendout('date')"
                  />
                </div>
              </div>
              <div class="row d-flex justify-content-end mt-2">
                <div class="col-auto ms-auto">
                  <button
                    v-if="!editSendingDate"
                    class="btn btn-outline-secondary btn-sm"
                    @click="editSendingDate = true"
                  >
                    Edit
                  </button>
                  <button
                    v-else
                    class="btn btn-outline-dark btn-sm"
                    @click="editSendingDate = false"
                  >
                    <i class="far fa-times me-1" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body" v-else>
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-4">Sent At</h4>
                  <p class="fw-bold text-secondary mb-0">
                    {{ sendout.sent | formatDate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->

        <div class="col-md-4">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-0">List</h4>
                  <p v-if="!editList" class="fw-bold text-secondary py-2 mb-0">
                    {{ sendout.marketing_list.name }}
                  </p>
                  <select
                    v-if="editList"
                    v-model="sendout.marketing_list_id"
                    @change="updateSendout('list')"
                    class="form-control form-control-sm py-2"
                    id="selectList"
                  >
                    <option v-for="list in lists" :value="list.id">
                      {{ list.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row d-flex justify-content-end mt-2">
                <div class="col-auto ms-auto">
                  <button
                    v-if="!sendout.sent && !editList"
                    class="btn btn-outline-secondary btn-sm"
                    @click="editList = true"
                  >
                    Edit
                  </button>
                  <button
                    v-else
                    class="btn btn-outline-dark btn-sm"
                    @click="editList = false"
                  >
                    <i class="far fa-times me-1" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->

        <div class="col-md-4">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-0">Template</h4>
                  <p
                    v-if="!editTemplate"
                    class="fw-bold text-secondary mb-0 py-2"
                  >
                    {{ sendout.mail_template.name }}
                  </p>
                  <select
                    v-if="editTemplate"
                    v-model="sendout.marketing_email_template_id"
                    @change="updateSendout('template')"
                    class="form-control form-control-sm py-2"
                    id="selectTemplate"
                  >
                    <option v-for="template in templates" :value="template.id">
                      {{ template.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row d-flex justify-content-end mt-2">
                <div class="col-auto ms-auto">
                  <button
                    v-if="!editTemplate"
                    class="btn btn-outline-secondary btn-sm me-3"
                    @click="showPreview = true"
                  >
                    Preview
                  </button>
                  <button
                    v-if="!sendout.sent && !editTemplate"
                    class="btn btn-outline-secondary btn-sm"
                    @click="editTemplate = true"
                  >
                    Edit
                  </button>
                  <button
                    v-else
                    class="btn btn-outline-dark btn-sm"
                    @click="editTemplate = false"
                  >
                    <i class="far fa-times me-1" />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>

      <!--  -->

      <div class="row mb-4">
        <div class="col-md-3">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-4">Sent</h4>
                  <h3 class="fw-bold text-secondary mb-0">
                    {{ sendout.sent_count }}
                  </h3>
                </div>
                <div class="col-auto ms-auto my-auto">
                  <i class="far fa-mail-bulk fa-3x text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="col-md-3">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-4">Delivered</h4>
                  <h3 class="fw-bold text-secondary mb-0">
                    {{ sendout.delivered }}
                  </h3>
                </div>
                <div class="col-auto ms-auto my-auto">
                  <i class="far fa-envelope fa-3x text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->

        <div class="col-md-3">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-4">Opens</h4>
                  <h3 class="fw-bold text-secondary mb-0">
                    {{ sendout.opened }}
                  </h3>
                </div>
                <div class="col-auto ms-auto my-auto">
                  <i class="far fa-envelope-open fa-3x text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->

        <div class="col-md-3">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="fw-bold text-primary mb-4">Clicks</h4>
                  <h3 class="fw-bold text-secondary mb-0">
                    {{ sendout.clicked }}
                  </h3>
                </div>
                <div class="col-auto ms-auto my-auto">
                  <i class="far fa-mouse fa-3x text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>

      <!--  -->

      <div class="row" v-if="!sendout.sent">
        <div class="col-auto ms-auto">
          <button class="btn btn-outline-danger btn-sm" @click="deleteSendout">
            <i class="far fa-trash me-2"></i>Delete Sendout
          </button>
        </div>
      </div>

      <div v-if="sendout.sent">
        <h4 class="fw-bold text-primary mb-2">Emails</h4>
        <marketing-email-inbox :sendout="sendout" />
      </div>

      <!--  -->
    </div>
    <loading v-else></loading>

    <marketing-email-template-preview-modal
      v-if="showPreview"
      @closed="showPreview = false"
      :marketing-email-template-id="sendout.marketing_email_template_id"
    />
  </div>
</template>

<script>
import MarketingEmailInbox from "@/views/components/inbox/MarketingEmailInbox";
import MarketingEmailTemplatePreviewModal from "@/views/areas/marketing-suite/marketing-email-templates/partials/MarketingEmailTemplatePreviewModal";

export default {
  data() {
    return {
      sendout: null,
      showPreview: false,
      editList: false,
      editTemplate: false,
      editSendingDate: false,
      lists: null,
      templates: null,
    };
  },
  methods: {
    async fetchSendout() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/marketing-sendouts/" +
          this.$route.params.id
      );

      this.sendout = data;
    },
    async deleteSendout() {
      if (
        confirm(
          "Are you sure you wish to delete the marketing sendout, this action cannot be undone?"
        )
      ) {
        const { data } = await this.$axios.delete(
          "/marketing-sendouts/" + this.$route.params.id
        );
        this.$router.push("/marketing");
        this.$EventBus.$emit("alert", { message: data.message });
      }
    },
    updateSendout(optionToEdit) {
      this.$axios
        .put(
          process.env.VUE_APP_API_URL +
            "/marketing-sendouts/" +
            this.sendout.id,
          this.sendout
        )
        .then(({ data }) => {
          if (optionToEdit === "list") {
            this.editList = false;
          } else if (optionToEdit === "template") {
            this.editTemplate = false;
          } else {
            this.editSendingDate = false;
          }
          this.fetchSendout();
        });
    },
    async fetchLists() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/marketing-lists"
      );
      this.lists = data;
    },
    async fetchTemplates() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/marketing-email-templates"
      );
      this.templates = data;
    },
  },
  created() {
    this.fetchSendout();
    this.fetchLists();
    this.fetchTemplates();
  },
  components: {
    MarketingEmailTemplatePreviewModal,
    MarketingEmailInbox,
  },
};
</script>

<style>
</style>
